import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/Seo/seo"
import Footer from "../components/Footer/footer";
import Button from '../components/Button/button'
import { TypographyStyle, GoogleFont } from 'react-typography'
import typography from '../utils/typography'
import { injectIntl, intlShape } from "react-intl";
import { compose } from "recompose";
import withPageContext from "../pageContext";

const NotFoundPage = (props) => (
  <Layout location={props.uri} isStatic={true}>
    <SEO title={props.intl.formatMessage({ id: 'not_found.title' })} />
    <TypographyStyle typography={typography} />
    <GoogleFont typography={typography} />
    <div className="static-page-container">
      <div className="row">
        <div className="col-12 center">
          <h1 style={{ color: 'var(--blue)', fontWeight: 700 }}>404</h1>
          <h2>{props.intl.formatMessage({ id: 'not_found.title' })}</h2>
          <Button text={props.intl.formatMessage({ id: 'not_found.button' })} link={'/'} />
        </div>
      </div>
    </div>
    <Footer />
  </Layout>
)

NotFoundPage.propTypes = {
  intl: intlShape.isRequired
};

export default compose(
  withPageContext,
  injectIntl
)(NotFoundPage)

